/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/updates'
import { Title, PDF, Loading } from './components';

const ANP = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <iframe width="560" height="315" src="https://www.youtube.com/embed/p9xjsMN-BkU?si=RlCXgO8AilBZ5B1E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <Title text="TÉRMINOS DE REFERENCIA PARA LA ELABORACIÓN DE PROGRAMAS DE MANEJO DE LAS ÁREAS NATURALES PROTEGIDAS COMPETENCIA DE LA FEDERACIÓN" />
      {content?.manejoANP && <PDF file={content?.manejoANP} title="TÉRMINOS DE REFERENCIA PARA LA ELABORACIÓN DE PROGRAMAS DE MANEJO DE LAS ÁREAS NATURALES PROTEGIDAS COMPETENCIA DE LA FEDERACIÓN" />}
    </div>
  );
}

export default ANP;
