/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/updates'
import { Title, PDF, Loading } from './components';

const Tulum = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <iframe width="560" height="315" src="https://www.youtube.com/embed/qrmx5JJVyXM?si=TG4SbReLxG6BmlIs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <Title text="Extracto del DOF" />
      <div className={styles.quote}>
        <p>Considerando que existe infraestructura turística dentro del Parque Nacional Tulum, y que corresponde a la Federación ejercer las atribuciones exclusivas que se le confieren a efecto de resolver el destino de las construcciones turísticas ubicadas en el Parque Nacional Tulum de acuerdo con la sentencia dictada por el Tribunal Pleno de la Suprema Corte de Justicia de la Nación en la Controversia Constitucional 72/2008, es necesario que todo particular con posesión de desarrollos hoteleros, restaurantero o de relajación (centros de SPA) colaboren de manera cercana con la SEMARNAT y atienda las solicitudes emitidas por parte de la Dirección, sujetándose a lo dispuesto en el presente Programa de Manejo con la finalidad de evitar el crecimiento desordenado de la misma y a partir de ello, prevenir riesgos sobre la conservación de los recursos naturales y el equilibrio ecológico del área natural protegida, que pudieran surgir por el cambio de uso de suelo y la pérdida de la conectividad ecológica, la perturbación de la biodiversidad y sus procesos biológicos y evolutivos, la contaminación del suelo y de los cenotes y ríos subterráneos, entre otros impactos.</p>
        <br />
        <p>El presente Programa de Manejo tiene como finalidad preservar y conservar la conformación escénica y paisajística, así como el equilibrio ecológico del Parque Nacional Tulum, minimizar al máximo los eventuales impactos antropogénicos, por lo anterior, y en apego a la Sentencia a la Controversia Constitucional 72/2008 emitida por el Tribunal Pleno de la Suprema Corte de Justicia de la Nación, que indica que "deberá ser la Federación la que, en ejercicio pleno de su jurisdicción sobre estos bienes y atendiendo, en todo momento, a su preservación, resuelva la situación de las construcciones existentes en el área, para lo cual podrá coordinarse con el estado de Quintana Roo y los Municipios demandados, estableciendo la forma y términos en que éstos intervendrán", se reconoce la presencia de infraestructura que presta diferentes servicios turísticos en el Parque Nacional Tulum, por lo que el mantenimiento de esta deberá realizarse sin generar impactos negativos a los ecosistemas, no se podrá ampliar, ni darles un uso diferente al ya establecido en el presente instrumento.</p>
      </div>
      <Title text="DOF - Diario Oficial de la Federacion NPM de Tulum Qrro. 2024" />
      {content?.decreto && <PDF file={content?.decreto} title="DOF Diario Oficial de la Federacion NPM de Tulum Qrro. 2024" />}
    </div>
  );
}

export default Tulum;
