/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/11'
import { Loading, Image, Pagination } from './components';

const Anexo11 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <Image img={content?.img1} alt="Entrada Elementos Naturales" />
      <Image img={content?.img2} alt="Entrada Elementos Naturales" />
      <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vR_lcRKucazIQiYlMyUAmE_ddlvvCtnC98XP2hK6Wb_SVf6KSZ5ltncnlX_40G6Jw/embed?start=false&loop=true&delayms=3000" frameborder="0" width="800" height="520" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
      <Pagination prev="/10" prevText="anterior" next="/12" nextText="siguiente" />
    </div>
  );
}

export default Anexo11;
