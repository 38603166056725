import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/04'
import { PDF, Loading, Pagination } from './components';

const Anexo04 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      {content?.pdf && <PDF file={content?.pdf} title="Constancias y Solicitudes de indemnización en diferentes años por Gloria Tello" />}
      <Pagination prev="/03" prevText="anterior" next="/05" nextText="siguiente" />
    </div>
  );
}

export default Anexo04;
