import img1 from './CartaAMLOfoto1.jpg';
import img2 from './CartaAMLOfoto2.jpg';
import img3 from './CartaAMLOfoto3.jpg';
import img4 from './CartaAMLO2019envioDHL.jpg';
import pdf from './CartaAMLO2019.pdf'

const content = {
  img1,
  img2,
  img3,
  img4,
  pdf
}

export default content;
