import img1 from './AntecedentesNoPenales2006pichi.jpg'
import img2 from './CertificadodenoadeudoenelpredioEmitidoporlaSecretariadelGobiernodeChiapas1992-pichi.jpg'
import img3 from './PagosImpuestosPrediales1988a1992pichi.jpg';
import img4 from './Plano_original_del_predio-pichi.jpg';
import img5 from './casa-en-construccion.jpg'
import img6 from './papayas.jpg'
import pdf1 from './EscriturasdelPredioenPalenqueChiapas.pdf';
import pdf2 from './PoderEspecialIrrevocable2003.pdf';
import pdf3 from './PoderNotarialEspecialIrrevocable2004.pdf';
import pdf4 from './Testamentos.pdf';

const content = {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  pdf1,
  pdf2,
  pdf3,
  pdf4
}

export default content;
