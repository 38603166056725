import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/02'
import { Image, PDF, Loading, Pagination } from './components';

const Anexo02 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <Image img={content?.img1} alt="Carta AMLO 2019 (1)" />
      <Image img={content?.img2} alt="Carta AMLO 2019 (2)" />
      <Image img={content?.img3} alt="Carta AMLO 2019 (3)" />
      <Image img={content?.img4} alt="Carta AMLO 2019 envío DHL" />
      {content?.pdf && <PDF file={content?.pdf} title="Carta AMLO 2019" />}
      <Pagination prev="/01" prevText="anterior" next="/03" nextText="siguiente" />
    </div>
  );
}

export default Anexo02;
