import img0 from './1b-Las-siguientes-imagenes-corresponden-a-los-horarios-y-dias-senalados-en-el-cartel-pichi.jpg';
import img1 from './1-26-07-2014-en-Lausanne-10-25-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img2 from './2-26-07-2014-en-Lausanne-10-26-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img3 from './3-26-07-2014-en-Lausanne-12-00-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img4 from './4-26-07-2014-en-Lausanne-12-00-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img5 from './5-02-08-2014-en-Fribourg-09-17-horas-En-defensa-del-petroleo-mexicano-1-pichi.jpg';
import img6 from './6-02-08-2014-en-Fribourg-11-59-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img7 from './7-02-08-2014-en-Berna-13-06-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img8 from './8-02-08-2014-en-Berna-13-07-horas-En-defensa-del-petroleo-mexicano-5-pichi.jpg';
import img9 from './9-02-08-2014-en-Berna-13-08-horas-En-defensa-del-petroleo-mexicano-6-pichi.jpg';
import img10 from './10-02-08-2014-en-Berna-15-20-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img11 from './11-02-08-2014-en-Zurich-16-41-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img12 from './12-02-08-2014-en-Zurich-16-50-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img13 from './13-08-2014-en-Zurich-18-34-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img14 from './14-02-08-2014-en-Zurich-19-00-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img15 from './15-02-08-2014-en-Zurich-19-00-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img16 from './16-03-08-2014-en-Ginebra-15-14-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img17 from './17-03-08-2014-en-Ginebra-16-57-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img18 from './18-En-Ginebra-03-08-2014-16-58-horas-En-defensa-del-petroleo-mexicano-pichi.jpg';
import img19 from './Enviando-al-sr-Loera-8-firmas-recolectadas-en-diferentes-ciudades-de-Suiza-pichi.jpg';
import img20 from './boletos-rifa.jpg';

const content = {
  img0,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
};

export default content;
