import img1 from './01.png';
import img2 from './02.png';
import img3 from './03.png';
import img4 from './04.png';
import img5 from './05.jpg';
import img6 from './06.jpg';
import img7 from './07.jpg';
import img8 from './08.jpg';
import img9 from './09.jpg';
import img10 from './10.jpg';
import pdf from './Justificativo-Modificacion-Parque-Nacional-Palenque.pdf';

const content = {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  pdf
};

export default content;
