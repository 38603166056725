import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/07'
import { Image, PDF, Loading, Pagination } from './components';

const Anexo07 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <Image img={content?.img1} alt="Pagos de inscripciones y derechos" />
      <Image img={content?.img2} alt="Solicitud de informes AC - SEDESOL" />
      <Image img={content?.img3} alt="Solicitud de informes AC 2 - SEDESOL" />
      {content?.pdf1 && <PDF file={content?.pdf1} title="Acta constitutiva Elementos Naturales Investigacion AC" />}
      {content?.pdf2 && <PDF file={content?.pdf2} title="Informe anual 2006 ENI" />}
      {content?.pdf3 && <PDF file={content?.pdf3} title="Informe anual 2007 ENI" />}
      <Pagination prev="/06" prevText="anterior" next="/08" nextText="siguiente" />
    </div>
  );
}

export default Anexo07;
