/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/12'
import { Image, PDF, Loading, Pagination } from './components';

const Anexo12 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vS856-9FwhNylTclE-rO_lVPWVit1vJHVRzAfCvTx0I_VhX-HD59k25x_6LVrFUBw/embed?start=false&loop=true&delayms=3000" frameborder="0" width="800" height="520" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
      {content?.pdf1 && <PDF file={content?.pdf1} title="100% Mexicano Propuesta Food-Truck" />}
      {content?.pdf4 && <PDF file={content?.pdf4} title="Diplôme LRDBHD" />}
      {content?.pdf2 && <PDF file={content?.pdf2} title="Certificat création d'entreprise" />}
      {content?.pdf3 && <PDF file={content?.pdf3} title="Certificat création d'entreprise 2" />}
      <Image img={content?.img1} alt="Attestation Contrats-types salaries" />
      <Image img={content?.img2} alt="Attestation d'engagement étrangers" />
      <Image img={content?.img3} alt="Attestation marchés publics" />
      <Image img={content?.img4} alt="Attestation système prévention" />
      <Pagination prev="/11" prevText="anterior" next="/13" nextText="siguiente" />
    </div>
  );
}

export default Anexo12;
