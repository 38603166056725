import decreto from './DOF-Diario-Oficial-de-la-Federacion-NPM-de-Tulum-Qrro-2024.pdf';
import respuestasPalenque from './respuestas_2023-2024.pdf';
import manejoANP from './terminos-de-referencia-para-la-elaboracion-de-las-ANP-Federación.pdf';

const content = {
  decreto,
  respuestasPalenque,
  manejoANP,
};

export default content;
