import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/01'
import { Image, PDF, Loading, Pagination } from './components';

const Anexo01 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <Image img={content?.img1} alt="En la operadora de viajes 1999" />
      <Image img={content?.img2} alt="VT Profesionales" />
      <Image img={content?.img3} alt="VT" />
      {content?.pdf1 && <PDF file={content?.pdf1} title="Escaneo de folletos" />}
      <Pagination next="/02" nextText="siguiente" />
    </div>
  );
}

export default Anexo01;
