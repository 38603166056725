import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/14';
import { Title, PDF, Loading, Pagination } from './components';

const Anexo14 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <Title text="Acoso de Autoridades" />
      {content?.pdf1 ? <PDF file={content?.pdf1} title="Acoso de SECTUR" /> : <Loading />}
      {content?.pdf2 && <PDF file={content?.pdf2} title="Conanp artesanias" />}
      {content?.pdf3 && <PDF file={content?.pdf3} title="Oficio Cananp Conaculta INAH" />}
      {content?.pdf4 && <PDF file={content?.pdf4} title="Oficio No. PNP_069_2020 Recibido RGH" />}
      <Pagination prev="/13" prevText="anterior" next="/" nextText="inicio" />
    </div>
  );
}

export default Anexo14;
