import img1 from './operadora_de_viajes_VT_1999.jpg'
import img2 from './VT-Profesionales.jpg';
import img3 from './VT.jpg';
import pdf1 from './ESCANEO_DE_FOLLETOS_b.pdf';

const content = {
  img1,
  img2,
  img3,
  pdf1,
};

export default content;
