/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styles from './anexos.module.scss';

import { Title, Loading, Pagination } from './components';

const Anexo08 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <Title text="Primer Festival Musical EN Palenque 2003" />
      <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQTY8AF5VztwPYVSfavbKX0vN_Lr9ShqqxuhWxTh7WsC11tgCSrzCKHwVfxhMIuIQ/embed?start=false&loop=true&delayms=3000" frameborder="0" width="800" height="520" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
      <iframe width="800" height="450" src="https://www.youtube.com/embed/iuWuc9miMaQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

      <Title text="Festival Equinoccio 2003" />
      <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTDnJcMoSfC9bwN96YvXfiDIoBq4bA4G5TKjGqnb20bqSiQFqEZr-V8oC-SwwVC5Q/embed?start=false&loop=true&delayms=3000" frameborder="0" width="800" height="520" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>

      <Title text="Festival Musical Palenque EN Verano 2003" />
      <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQt2bolJ3LkXATrHtiIqHBd7GmOVE_ZVvYOG9mnMWNUI9nSRxTUnJoWzWaQ7oILng/embed?start=false&loop=true&delayms=3000" frameborder="0" width="800" height="520" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>

      <Title text="Festival Musical de Regreso EN casa 2006" />
      <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vS30AmBaoGqKEQpZLRb9_gNdHiH5pmTko8YcbmAVvQvVWq2ByRbXeU5A2s6W57OzQ/embed?start=false&loop=true&delayms=3000" frameborder="0" width="800" height="520" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>

      {/*
      <iframe width="560" height="315" src="https://www.youtube.com/embed/iuWuc9miMaQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      */}
      <Pagination prev="/07" prevText="anterior" next="/09" nextText="siguiente" />
    </div>
  );
}

export default Anexo08;
