import img1 from './Attestation-Contrats-types-salaries.jpg';
import img2 from './Attestation-dengagement-etrangers.jpg';
import img3 from './Attestation-marches-publics.jpg';
import img4 from './Attestation-systeme-prevention.jpg';
import pdf1 from './100-Mexicano-Propuesta-Food-Truck.pdf';
import pdf2 from './Certificat-creation-dentreprise.pdf';
import pdf3 from './Certificat-creation-dentreprise-2.pdf';
import pdf4 from './Diplome-LRDBHD.pdf';

const content = {
  img1,
  img2,
  img3,
  img4,
  pdf1,
  pdf2,
  pdf3,
  pdf4,
};

export default content;
