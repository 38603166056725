import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Hamburger from 'hamburger-react'
import styles from './styles.module.scss';

import logo from '../assets/logo.svg';
import Menu from '../menu';
import ScrollToTop from './scrollToTop';
import { SecurityCode } from '../pages/components';

const Header = ({ isOpen, setOpen, isDesktop }) => {
  return (
    <header className={styles.header}>
      {!isDesktop && (
        <div className={styles.button}>
          <Hamburger toggled={isOpen} toggle={setOpen} direction="left" />
        </div>
      )}
      <Link to="/" className={styles.logo}>
        <img src={logo} alt="Elementos Naturales" />
        <span className={styles.title}>elementos naturales</span>
      </Link>
    </header>
  );
};

const UpdateSections = () => (
  <div className={styles.topSections}>
    <Link to="/tulum">Decreto Tulum - 12 enero 2024 <br /> Incluyendo industria turística existente</Link>
    <Link to="/palenque">Respuestas a oficios Palenque <br /> Estudio justificativo modificación de PN a ANP de la CONANP 2016</Link>
    <Link to="/manejo-anp">Términos de referencia para la elaboración de programas de manejo de áreas naturales protegidas competencia de la federación</Link>
  </div>
);

const Layout = () => {
  const [width, setWindowWidth] = useState(0);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const isDesktop = width > 1023;

  return (
    <ScrollToTop>
      <div className={styles.container}>
        <SecurityCode />
        <div className={styles.top}>
          <Header isOpen={isDesktop ? true : isOpen} setOpen={setOpen} isDesktop={isDesktop} />
        </div>
        <UpdateSections />
        <div className={styles.content}>
          <Menu isOpen={isOpen} setOpen={setOpen} isDesktop={isDesktop} />
          <div className={styles.page}>
            <Outlet />
          </div>
        </div>
      </div>
    </ScrollToTop>
  );
};

export default Layout;
