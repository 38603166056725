/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/05'
import other from '../assets/updates'
import { Title, PDF, Loading } from './components';

const Palenque = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <iframe width="560" height="315" src="https://www.youtube.com/embed/4vnNkZ7AP_A?si=IDXkMrVYJM0Gfrcg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <Title text="Respuestas a oficios, Palenque 2023-2024" />
      {other?.respuestasPalenque && <PDF file={other?.respuestasPalenque} title="Respuestas a oficios, Palenque 2023-2024" />}
      <Title text="Justificativo Modificación Parque Nacional Palenque" />
      {content?.pdf && <PDF file={content?.pdf} title="Justificativo Modificación Parque Nacional Palenque" />}
    </div>
  );
}

export default Palenque;
