import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './components.module.scss';
import loadingGif from '../assets/loading.gif';
import logo from '../assets/logoEN.png';

export const Title = ({ text }) => (
  <div className={styles.title}>
    <h4>{text}</h4>
  </div>
);

export const Image = ({ img, alt }) => (
  <div className={styles.image}>
    <p>{alt}</p>
    <img src={img} alt={alt} />
  </div>
);

export const PDF = ({ file, title }) => (
  <div className={styles.pdf}>
    <embed
      src={file}
      type="application/pdf"
      width="830"
      height="700"
      title={title}
    />
  </div>
);

export const Loading = () => {
  const [active, setActive] = useState(true);
  setTimeout(() => {
    setActive(false)
  }, 520)
  const output = active && (
    <div className={styles.loadingGif}>
      <p>cargando...</p>
      <img src={loadingGif} alt="cargando" />
    </div>
  );
  return output;
}

export const Pagination = ({ prev, prevText, next, nextText }) => {
  return (
    <div className={styles.pagination}>
      <div className={styles.paginationContent}>
        {prev && prevText && (
          <div className={styles.paginationLink}>
            <Link to={prev} onClick={() => window?.scroll(0, 0)}>{prevText}</Link>
          </div>
        )}
        {next && nextText && (
          <div className={styles.paginationLink}>
            <Link to={next} onClick={() => window?.scroll(0, 0)}>{nextText}</Link>
          </div>
        )}
      </div>
      <div className={styles.paginationBack}>
        <Link to="/">Regresar a Introducción</Link>
      </div>
    </div>
  );
}

export const SecurityCode = () => {
  const [isSecretUnlocked, setIsSecretUnlocked] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const validCode = inputValue === 'Mig292929'
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleInputSubmit = () => {
    if (validCode) {
      setIsSecretUnlocked(true);
    }
  };
  return (
    <div>
      {!isSecretUnlocked && (
        <div className={styles.codeContainer}>
          <div className={styles.codeContent}>
            <div className={styles.codeImage}>
              <img src={logo} alt="Elementos Naturales" />
            </div>
            <form onSubmit={handleInputSubmit}>
              <label>
                <input type="password" placeholder="******" value={inputValue} onChange={handleInputChange} />
              </label>
              <button type="button" onClick={handleInputSubmit} disabled={!validCode}>Entrar</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}