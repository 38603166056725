import img1 from './PagosInscripcionesyDerechos.jpg';
import img2 from './SolicitudDeInformesAC-SEDESOL.jpg';
import img3 from './SolicitudDeInformesAC-SEDESOL2.jpg';
import pdf1 from './ActaConstitutivaElementosNaturalesInvestigacionAC.pdf';
import pdf2 from './InformeAnual2006ENI.pdf';
import pdf3 from './InformeAnual2007ENI.pdf';

const content = {
  img1,
  img2,
  img3,
  pdf1,
  pdf2,
  pdf3
};

export default content;
