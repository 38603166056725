/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/05'
import { Title, Image, PDF, Loading, Pagination } from './components';

const Anexo05 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <Image img={content?.img1} alt="Consta del reconocimiento de la CONANP, del error en los polígonos del PNP." />
      <Image img={content?.img2} alt="Lineamientos que son permitidos en el Art. 50 de la LGEEPA y hemos actuado." />
      <Image img={content?.img3} alt="Muestra el Plan de Manejo con actividades que son ideales y en ellos enfocados." />
      <Image img={content?.img4} alt="La CONANP reconociendo nuestra existencia, sin estar asentadas del todo nuestras actividades." />
      <Image img={content?.img5} alt="Invitación general a los afectados para modificación del Decreto del PNP." />
      <Image img={content?.img6} alt="Oficio emitido por la CONANP PNP/426/2016. Otorgando el Plan Justificativo de Modificación." />
      <Image img={content?.img7} alt="Agenda del taller." />
      <Image img={content?.img8} alt="Hoja de sondeo para enviar comentarios. Ausencia del país en esos momentos." />
      <Image img={content?.img9} alt="Oficio PNP/377/2016 Invitación al taller de modificación del Parque Nacional Palenque." />
      <Image img={content?.img10} alt="Plano del desface en las coordenadas, se desprotegen áreas y protegen otras. " />
      <Title text="Documento completo" />
      {content?.pdf && <PDF file={content?.pdf} title="Justificativo Modificación Parque Nacional Palenque" />}
      <Pagination prev="/04" prevText="anterior" next="/06" nextText="siguiente" />
    </div>
  );
}

export default Anexo05;
