import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Layout from './layout';
import Intro from './pages/intro';
import Anexo01 from './pages/anexo01';
import Anexo02 from './pages/anexo02';
import Anexo03 from './pages/anexo03';
import Anexo04 from './pages/anexo04';
import Anexo05 from './pages/anexo05';
import Anexo06 from './pages/anexo06';
import Anexo07 from './pages/anexo07';
import Anexo08 from './pages/anexo08';
import Anexo09 from './pages/anexo09';
import Anexo10 from './pages/anexo10';
import Anexo11 from './pages/anexo11';
import Anexo12 from './pages/anexo12';
import Anexo13 from './pages/anexo13';
import Anexo14 from './pages/anexo14';
import Tulum from './pages/tulum';
import Palenque from './pages/palenque';
import ANP from './pages/manejo-anp';
import Error404 from './pages/error';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Intro />} />
        <Route path="01" element={<Anexo01 />} />
        <Route path="02" element={<Anexo02 />} />
        <Route path="03" element={<Anexo03 />} />
        <Route path="04" element={<Anexo04 />} />
        <Route path="05" element={<Anexo05 />} />
        <Route path="06" element={<Anexo06 />} />
        <Route path="07" element={<Anexo07 />} />
        <Route path="08" element={<Anexo08 />} />
        <Route path="09" element={<Anexo09 />} />
        <Route path="10" element={<Anexo10 />} />
        <Route path="11" element={<Anexo11 />} />
        <Route path="12" element={<Anexo12 />} />
        <Route path="13" element={<Anexo13 />} />
        <Route path="14" element={<Anexo14 />} />
        <Route path="tulum" element={<Tulum />} />
        <Route path="palenque" element={<Palenque />} />
        <Route path="manejo-anp" element={<ANP />} />
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  );
};

export default App;
