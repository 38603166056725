import pdf1 from './Acoso-SECTUR.pdf';
import pdf2 from './Oficios-a-Conaculta.pdf';
import pdf3 from './Profepa-Acta-de-Inspeccion.pdf';
import pdf4 from './Profepa-orden-de-Inspeccion.pdf';

const content = {
  pdf1,
  pdf2,
  pdf3,
  pdf4,
};

export default content;
