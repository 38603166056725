import React from 'react';
import { Link } from 'react-router-dom';
import styles from './error.module.scss';

const Error404 = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <b>Error 404</b>
        <p>La página que estás buscando no existe</p>
        <Link to="/">regresar al inicio</Link>
      </div>
    </div>
  );
}

export default Error404;
