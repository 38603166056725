/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styles from './anexos.module.scss';

import { Loading, Pagination } from './components';
import video from '../assets/10/video.mp4';

const Anexo10 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQRsicVmAJoM6_AKojsFUuQ67fGJfdPWG41tLKgD-pYzc03LOIRBprc0JQ37rGUTA/embed?start=false&loop=false&delayms=3000" frameborder="0" width="800" height="520" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
      <video width="640" height="480" controls>
        <source src={video} type="video/mp4" />
      </video>
      <Pagination prev="/09" prevText="anterior" next="/11" nextText="siguiente" />
    </div>
  );
}

export default Anexo10;
