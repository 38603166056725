import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/03'
import { Image, PDF, Loading, Pagination } from './components';

const Anexo03 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <Image img={content?.img5} alt="Casa en construcción 1981. Observando el tipo de vegetación, compuesta mayormente de milpa, acahuales y potrero." />
      <Image img={content?.img6} alt="Aprovechamiento sustentable (papayas)" />
      <Image img={content?.img1} alt="Antecedentes no penales 2006" />
      <Image img={content?.img2} alt="Certificado de no adeudo en el predio. Emitido por la Secretaría del Gobierno de Chiapas 1992" />
      <Image img={content?.img3} alt="Pagos de impuestos prediales 1988 a 1992" />
      <Image img={content?.img4} alt="Plano original del predio" />
      {content?.pdf1 && <PDF file={content?.pdf1} title="Escrituras del predio en Palenque, Chiapas" />}
      {content?.pdf2 && <PDF file={content?.pdf2} title="Poder especial irrevocable 2003" />}
      {content?.pdf3 && <PDF file={content?.pdf3} title="Poder notarial especial irrevocable 2004" />}
      {content?.pdf4 && <PDF file={content?.pdf4} title="Testamentos" />}
      <Pagination prev="/02" prevText="anterior" next="/04" nextText="siguiente" />
    </div>
  );
}

export default Anexo03;
