/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styles from './anexos.module.scss';

import { Loading, Pagination } from './components';

const Anexo13 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQK8PzPiS6BdnQ4_S0Q99BCt2poGd50blI3ocr1igGNmihH8KQOolJN4wkb6bPRTQ/embed?start=false&loop=true&delayms=3000" frameborder="0" width="800" height="520" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
      <Pagination prev="/12" prevText="anterior" next="/14" nextText="siguiente" />
    </div>
  );
}

export default Anexo13;
