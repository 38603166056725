import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/09'
import { Image, Loading, Pagination } from './components';

const Anexo09 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <Image img={content?.img0} alt="Las siguientes imágenes corresponden a los horarios y días senalados en el cartel" />
      <Image img={content?.img1} alt="26/07/2014 en Lausanne 10:25 En defensa del petróleo mexicano" />
      <Image img={content?.img2} alt="26/07/2014 en Lausanne 10:26 En defensa del petróleo mexicano" />
      <Image img={content?.img3} alt="26/07/2014 en Lausanne 12:00 En defensa del petróleo mexicano" />
      <Image img={content?.img4} alt="26/07/2014 en Lausanne 12:00 En defensa del petróleo mexicano" />
      <Image img={content?.img5} alt="02/08/2014 en Fribourg 09:17 En defensa del petróleo mexicano" />
      <Image img={content?.img6} alt="02/08/2014 en Fribourg 11:59 En defensa del petróleo mexicano" />
      <Image img={content?.img7} alt="02/08/2014 en Berna 13:06 En defensa del petróleo mexicano" />
      <Image img={content?.img8} alt="02/08/2014 en Berna 13:07 En defensa del petróleo mexicano" />
      <Image img={content?.img9} alt="02/08/2014 en Berna 13:08 En defensa del petróleo mexicano" />
      <Image img={content?.img10} alt="02/08/2014 en Berna 15:20 En defensa del petróleo mexicano" />
      <Image img={content?.img11} alt="02/08/2014 en Zurich 16:41 En defensa del petróleo mexicano" />
      <Image img={content?.img12} alt="202/08/2014 en Zurich 16:50 En defensa del petróleo mexicano" />
      <Image img={content?.img13} alt="02/08/2014 en Zurich 18:34 En defensa del petróleo mexicano" />
      <Image img={content?.img14} alt="02/08/2014 en Zurich 19:00 En defensa del petróleo mexicano" />
      <Image img={content?.img15} alt="02/08/2014 en Zurich 19:00 En defensa del petróleo mexicano" />
      <Image img={content?.img16} alt="03/08/2014 en Ginebra 15:14 En defensa del petróleo mexicano" />
      <Image img={content?.img17} alt="03/08/2014 en Ginebra 16:57 En defensa del petróleo mexicano" />
      <Image img={content?.img18} alt="03/08/2014 en Ginebra 16:58 En defensa del petróleo mexicano" />
      <Image img={content?.img19} alt="Enviando al Sr. Loera, 8 firmas recolectadas en diferentes ciudades de Suiza" />
      <Image img={content?.img20} alt="Participación en la rifa a modo de apoyo a la causa" />
      <iframe width="560" height="315" src="https://www.youtube.com/embed/r1YFTIzVQE0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/VSLqByDvqwU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <Pagination prev="/08" prevText="anterior" next="/10" nextText="siguiente" />
    </div>
  );
}

export default Anexo09;
